// http://jsfiddle.net/phpdeveloperrahul/DdsLY

(function ($) {
    $.fn.disableTab = function (tabIndex, hide) {

        // Get the array of disabled tabs, if any
        var disabledTabs = this.tabs("option", "disabled");

        if ($.isArray(disabledTabs)) {
            var pos = $.inArray(tabIndex, disabledTabs);

            if (pos < 0) {
                disabledTabs.push(tabIndex);
            }
        } else {
            disabledTabs = [tabIndex];
        }

        this.tabs("option", "disabled", disabledTabs);

        if (hide === true) {
            var index = $('#panel_bg li a:eq(0)');
            $(index).trigger("click");

            $(this).find('li:eq(' + tabIndex + ')').addClass('ui-helper-hidden');
        }

        // Enable chaining
        return this;
    };

    $.fn.enableTab = function (tabIndex) {
        $(this).find('li:eq(' + tabIndex + ')').removeClass('ui-helper-hidden');
        this.tabs("enable", tabIndex);
        return this;

        /* Old way, not really necessary
         
         // Get the array of disabled tabs, if any
         var disabledTabs = this.tabs("option", "disabled");
         
         var pos = $.inArray(tabIndex, disabledTabs);
         
         // If the tab we want is in the disabled list, remove it
         if (pos > -1) {
         disabledTabs.splice(pos);
         
         // Remove the hidden class just in case
         $(this).find('li:eq(' + tabIndex + ')').removeClass('ui-state-hidden');
         
         // Set the list of disabled tabs, without the one we just enabled
         this.tabs("option", "disabled", disabledTabs);
         }
         
         // Enable chaining
         return this;
         */
    };


})(jQuery);